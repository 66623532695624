import App from './App.vue'
import router from './router'
import store from './store'
import veeValidate from './plugins/vee-validate'
import Vue from 'vue'
import vuetify from './plugins/vuetify'
import services from './plugins/services'
import VMdEditor from './plugins/md-editor'

Vue.config.productionTip = false
Vue.prototype.$axios = services

export default new Vue({
    services,
    router,
    store,
    veeValidate,
    vuetify,
    VMdEditor,
    render: (h) => h(App)
}).$mount('#app')
