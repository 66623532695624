<template>
    <v-card width="600">
        <v-card-text class="pa-10">
            <span class="d-block mb-4">Ingresar datos</span>
            <validation-observer ref="observer" v-slot="{ invalid }">
                <v-form @submit.prevent="submit" class="form-password__content">
                    <v-switch v-model="isDoubleVerification" label="¿Requerir segundo factor de autenticacion?" />
                    <resource-input dense label="Suscripción" rules="required" type="text" clearable v-model="plan" disabled />
                </v-form>
                <v-btn block color="primary" elevation="0" @click="submit" :disabled="invalid">Guardar cambios</v-btn>
            </validation-observer>
        </v-card-text>
    </v-card>
</template>

<script>
import ResourceInput from '@/components/resource/Input.vue'

export default {
    props: {
        data: { type: Object, default: () => {} }
    },
    components: {
        ResourceInput
    },
    data() {
        return {
            isDoubleVerification: this.data.isDoubleVerification,
            plan: this.data.plan
        }
    },
    methods: {
        async submit() {
            try {
                const payload = { isDoubleVerification: this.isDoubleVerification, plan: this.plan }
                await this.$axios.put('system/activateDoubleVerification', payload)
                this.$store.commit('resource/ALERT', {
                    color: 'success',
                    text: `Configuracion general almacenada correctamente 👍`,
                    visible: true
                })
                this.$emit('getSetting')
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>
