<template>
    <v-card width="600">
        <v-card-title class="text-uppercase mb-4">
            <span>Agregar colaboradores</span>
            <v-spacer />
            <v-btn small icon @click="resetData">
                <v-icon small>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <validation-observer ref="observer" v-slot="{ invalid }">
                <div class="d-flex align-baseline">
                    <resource-input
                        class="colaborator-add__input mr-2"
                        label="Correo electrónico"
                        rules="email"
                        type="email"
                        clearable
                        v-model="email"
                    />
                    <v-btn color="primary" small fab @click="pushColaborator">
                        <v-icon>mdi-account-plus</v-icon>
                    </v-btn>
                </div>
                <div class="mb-4" v-if="colaborators.length > 0">
                    <h4 class="mb-2 text-uppercase">Colaboradores:</h4>
                    <div v-for="(data, index) in colaborators">
                        <v-chip label class="mr-2 mb-2">
                            <v-icon left small>mdi-account</v-icon>
                            <span class="d-block mr-4">{{ data.email }}</span>
                            <v-btn small icon @click="removeColaborator(index)">
                                <v-icon small>mdi-close</v-icon>
                            </v-btn>
                        </v-chip>
                    </div>
                </div>
            </validation-observer>
            <v-btn color="primary" block @click="submitColaborator">Agregar colaboradores</v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import ResourceInput from '@/components/resource/Input.vue'

export default {
    components: {
        ResourceInput
    },
    data() {
        return {
            colaborators: [],
            email: null
        }
    },
    methods: {
        async submitColaborator() {
            try {
                if (this.colaborators.length > 0) {
                    const uuid = this.$store.state.authentication.uuid
                    await this.$axios.post(`colaborators/${uuid}`, this.colaborators)
                    await this.$store.dispatch('authentication/getWorkspaces')
                    this.$store.commit('resource/ALERT', { color: 'success', text: `Nuevo colaborador agregado`, visible: true })
                    this.resetData()
                }
            } catch (error) {
                console.log(error)
            }
        },
        async pushColaborator() {
            const validate = await this.$refs.observer.validate()
            if (validate && this.email) this.colaborators.push({ email: this.email })
            this.email = null
        },
        removeColaborator(index) {
            if (index > -1) this.colaborators.splice(index, 1)
        },
        resetData() {
            this.colaborators = []
            this.email = null
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss">
.colaborator-add {
    &__input {
        width: 100%;
    }
}
</style>
