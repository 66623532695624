import axios from 'axios'
// baseURL: 'http://localhost:3000/v1'
// baseURL: 'http://155.138.175.248:3838/v1'


const config = {
    baseURL: 'https://ws.keeppassword.io/v1'
}

const service = axios.create(config)

service.interceptors.request.use(
    function (config) {
        const persist = localStorage.getItem('SECRETS_APP')
        const vuex = JSON.parse(persist)
        const token = vuex?.authentication?.token
        config.headers.Authorization = token
        return config
    },
    function (error) {
        localStorage.clear()
        return Promise.reject(error)
    }
)

export default service
