<template>
    <div
        :class="isGrid ? 'd-flex justify-space-between align-center px-2 pt-2' : 'd-flex flex-column elevation-1 rounded-lg'"
        class="password-component"
        @click="$emit('unlock', secret)"
    >
        <div :class="isGrid ? 'd-flex align-center' : 'd-block text-center mb-4'">
            <div v-if="!isGrid" class="password-component__menu">
                <option-menu :secret="secret"/>
            </div>
            <v-img
                :alt="secret.name"
                :class="isGrid ? 'mx-auto mr-4' : 'mx-auto my-4'"
                :src="secret.logo ? secret.logo : placeholder"
                class="password-component__image"
            />
            <h5 class="password-component__title text-uppercase font-weight-medium">{{ secret.name }}</h5>
        </div>
        <div>
            <v-btn
                :class="isGrid ? 'py-2' : 'w-full'"
                :color="isLocked ? 'green darken-1' : 'primary'"
                :fab="isGrid"
                :x-small="isGrid"
                dark
                depressed
            >
                <v-icon :left="!isGrid" dark>{{ isLocked ? 'mdi-lock-open-variant' : 'mdi-lock' }}</v-icon>
                <span v-if="!isGrid" class="text-caption font-weight-bold">Desbloquear</span>
            </v-btn>
            <option-menu v-if="isGrid" :secret="secret"/>
        </div>
    </div>
</template>

<script>
import OptionMenu from "@/components/password/OptionMenu.vue"

export default {
    components: { OptionMenu },
    props: {
        isLocked: { type: Boolean, default: false },
        isGrid: { type: Boolean, default: false },
        secret: { type: Object, default: () => ({}) }
    },
    computed: {
        placeholder() {
            return require('@/assets/images/placeholder.jpeg')
        }
    }
}
</script>

<style lang="scss">
.password-component {
    cursor: pointer;
    &:hover {
        background-color: #eeeeee;
        border-radius: 4px;
        transition: 0.3s;
    }
    &__image {
        height: 50px;
        max-width: 100px;
        width: 50px;
    }
    &__title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &__menu {
        position: absolute;
        top: 2px;
        right: 0;        
    }
    &>div:nth-child(1) {
        position: relative;
    }
}
.w-full {
    width: 100%;
}
</style>
