<template>
    <div class="d-flex">
        <div class="form-password__color d-flex align-center mb-4">
            <span>Color de la tarjeta de password</span>
            <div class="form-password__color-selected ml-4" :style="`background-color: ${selected}`" @click="$emit('show')" />
        </div>
        <v-color-picker
            v-if="viewColorPicker"
            v-model="innerValue"
            hide-canvas
            hide-inputs
            hide-sliders
            show-swatches
            swatches-max-height="100"
            mode="hexa"
            @input="$emit('hide')"
        />
    </div>
</template>

<script>
export default {
    props: {
        viewColorPicker: { type: Boolean, efault: false },
        selected: { type: String, default: '#FFFFFF' },
        value: { type: null }
    },
    data: () => ({
        innerValue: ''
    }),
    watch: {
        innerValue(newVal) {
            this.$emit('input', newVal)
        },
        value(newVal) {
            this.innerValue = newVal
        }
    },
    created() {
        if (this.value) this.innerValue = this.value
    }
}
</script>
