import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"width":"600"}},[_c(VCardTitle,{staticClass:"mb-4"},[_c('span',[_vm._v("Mover secret \""+_vm._s(_vm.secret.name)+"\" a otro workspace")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.closeModalMoveSecrets}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('span',{staticClass:"d-block mb-4"},[_vm._v(" Workspace actual: "+_vm._s(_vm.secret.workSpaces.name)+" ")]),_c(VSpacer),_c(VSelect,{attrs:{"items":_vm.workspaces,"label":"Selecciona un workspace","outlined":"","item-text":"name","item-value":"uuid","return-object":"","single-line":"","value":_vm.newWorkspace},on:{"change":_vm.selectWorkspace}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"loading":_vm.loading,"block":"","color":"primary","elevation":"0","disabled":_vm.invalid},on:{"click":_vm.move}},[_vm._v("Mover")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }