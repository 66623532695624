import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"width":"600"}},[_c(VCardText,{staticClass:"pa-10"},[_c('span',{staticClass:"d-block mb-4"},[_vm._v("Ingresar datos")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c(VForm,{staticClass:"form-password__content",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c(VSwitch,{attrs:{"label":"¿Requerir segundo factor de autenticacion?"},model:{value:(_vm.isDoubleVerification),callback:function ($$v) {_vm.isDoubleVerification=$$v},expression:"isDoubleVerification"}}),_c('resource-input',{attrs:{"dense":"","label":"Suscripción","rules":"required","type":"text","clearable":"","disabled":""},model:{value:(_vm.plan),callback:function ($$v) {_vm.plan=$$v},expression:"plan"}})],1),_c(VBtn,{attrs:{"block":"","color":"primary","elevation":"0","disabled":invalid},on:{"click":_vm.submit}},[_vm._v("Guardar cambios")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }