<template>
    <div>
        <v-skeleton-loader v-if="loading" class="border" type="image, article" />
        <v-card class="detail-component" v-else>
            <v-card-text v-if="!errorView">
                <v-card-subtitle class="px-0 d-flex">
                    <h3 class="d-block black--text font-weight-medium">{{ timeFormart }}</h3>
                    <v-spacer />
                    <v-btn icon @click="$emit('close')">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-subtitle>
                <div class="d-flex align-center">
                    <v-img width="50" max-width="100" class="mr-5" :src="secret.logo || placeholder" :alt="secret.name" />
                    <div class="mr-4">
                        <h3 class="text-uppercase text--primary">Password: {{ secret.name }}</h3>
                        <span class="d-block font-weight-medium body-2 text-uppercase text--secondary">
                            Workspace: {{ secret.workSpaces.name }}
                        </span>
                    </div>
                    <v-spacer />
                    <v-btn class="mr-1" icon @click="isFavoritePassword">
                        <v-icon :color="detail.isFavorite ? '#e74c3c' : ''">mdi-heart</v-icon>
                    </v-btn>
                    <v-btn class="mr-1" icon @click="$emit('editPassword', secret.uuid)">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn class="mr-1" icon @click="openShareSecret">
                        <v-icon>mdi-share-variant</v-icon>
                    </v-btn>
                </div>
                <div class="d-flex align-center justify-space-between mt-4 pb-2"></div>
                <template v-for="(data, index) in labels">
                    <div class="detail-component__content mb-4">
                        <h4 class="font-weight-bold">{{ data.label }}:</h4>
                        <div class="detail-component__detail">
                            <template v-if="data.value === 'linkWeb'">
                                <span @click="openNewWindow(detail[data.value])" class="d-block mr-2 detail-component__linkWeb">{{
                                    detail[data.value]
                                }}</span>
                            </template>
                            <template v-else-if="data.value === 'password'" class="d-block mr-2">
                                ******
                                <complex v-if="detail.complex" :complex="detail.complex" />
                            </template>
                            <span v-else class="d-block mr-2">{{ detail[data.value] }}</span>
                        </div>
                        <v-tooltip left v-model="data.show">
                            <template #activator="{ on }">
                                <v-btn
                                    class="detail-component__button"
                                    icon
                                    @click="$emit('copyData', { label: data.label, value: detail[data.value] })"
                                >
                                    <v-icon small>mdi-content-copy</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ `${data.label} copiado` }} </span>
                        </v-tooltip>
                    </div>
                </template>
                <template v-if="detail.tags">
                    <template v-for="(tag, index) in detail.tags">
                        <v-chip class="mr-2 mb-2" small label> {{ tag }} </v-chip>
                    </template>
                </template>
                <v-divider class="my-5" />
                <template v-if="detail.notes">
                    <h4 class="font-weight-bold mb-4">Notas</h4>
                    <v-md-editor v-model="detail.notes" mode="preview" height="300px" />
                    <v-divider class="my-5" />
                </template>
                <template v-if="detail.files">
                    <h4 class="font-weight-bold mb-4">Archivos</h4>
                    <template v-for="(file, index) in detail.files">
                        <v-chip class="mr-2 mb-2" label @click="downloadFile(file.name)">
                            <v-icon left small start>{{ getIcons(file.type) }}</v-icon>
                            {{ file.nameFile }}</v-chip
                        >
                    </template>
                    <v-divider class="my-5" />
                </template>
                <template v-if="detail.colaborators">
                    <h4 class="font-weight-bold mb-4">Colaboradores</h4>
                    <template v-for="(colaborator, index) in detail.colaborators">
                        <v-chip class="mr-2 mb-2" label v-if="colaborator">
                            <v-icon left small start>mdi-account</v-icon>
                            {{ colaborator }}</v-chip
                        >
                    </template>
                    <v-divider class="my-5" />
                </template>
                <template v-if="detail.fields">
                    <h4 class="font-weight-bold mb-4">Campos personalizados</h4>
                    <div class="detail-component__content--optional mb-2">
                        <template v-for="(field, index) in detail.fields">
                            <span class="d-block mr-2">{{ field.label }} : {{ field.value }}</span>
                        </template>
                    </div>
                </template>
            </v-card-text>
            <v-alert v-else color="error">Error al cargar el secreto {{ secret.name }}</v-alert>
        </v-card>
        <v-dialog v-model="shareSecret" width="auto">
            <share-password @close="closeShareSecret" :secret="secret" />
        </v-dialog>
    </div>
</template>

<script>
import SharePassword from '@/components/password/Share.vue'
import Complex from '@/components/password/Complex.vue'

export default {
    components: {
        SharePassword,
        Complex
    },
    data: () => ({
        shareSecret: false
    }),
    props: {
        detail: { type: Object, default: () => ({}) },
        labels: { type: Array, default: () => [] },
        secret: { type: Object, default: () => ({}) },
        errorView: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        timeFormart: { default: 0 }
    },
    computed: {
        placeholder() {
            return require('@/assets/images/placeholder.jpeg')
        }
    },
    methods: {
        getIcons(type) {
            const icons = {
                csv: 'mdi-file-excel',
                doc: 'mdi-file-word',
                docm: 'mdi-file-word',
                docx: 'mdi-file-word',
                json: 'mdi-code-json',
                pdf: 'mdi-file-pdf-box',
                plain: 'mdi-text-box-outline',
                xls: 'mdi-file-excel',
                xlsx: 'mdi-file-excel'
            }
            return icons[type] || 'mdi-paperclip'
        },
        async downloadFile(name) {
            try {
                const response = await this.$axios.get(`files/${name}`, {
                    responseType: 'blob'
                })
                const file = new Blob([response.data], { type: response.data.type })
                const fileURL = URL.createObjectURL(file)
                window.open(fileURL)
            } catch (error) {
                console.log(error)
            }
        },
        openNewWindow(data) {
            if (data.includes('www' || 'http')) window.open(data)
            else window.open(`https://${data}`)
        },
        async isFavoritePassword() {
            try {
                let isFavorite = !this.detail.isFavorite
                await this.$axios.put(`secrets/${this.secret.uuid}/isFavorite/${isFavorite}`)
                this.detail.isFavorite = isFavorite
            } catch (error) {
                // TODO apply handler error
                console.log(error.message)
            }
        },
        openShareSecret() {
            this.shareSecret = true
        },
        closeShareSecret() {
            this.shareSecret = false
        }
    }
}
</script>

<style lang="scss">
.detail-component {
    &__content {
        align-items: center;
        display: grid;
        gap: 16px;
        grid-template-columns: 100px 1fr 30px;
        &--optional {
            align-items: center;
            gap: 16px;
        }
    }
    &__linkWeb {
        cursor: pointer;
        text-decoration: underline;
        color: #0000ff;
    }
}
</style>
