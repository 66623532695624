<template>
    <v-chip class="mx-1" small :color="color" text-color="white">
        {{ complex }}
    </v-chip>
</template>

<script>
export default {
    props: {
        complex: { type: String, required: true },
    },
    computed: {
        color() {
            switch (this.complex) {
                case 'Very weak':
                    return "#e74c3c"
                    break;
                case 'Weak':
                    return "#e67e22"
                    break;
                case 'Medium':
                    return "#e67e22"
                    break;
                case 'Strong':
                    return "#2ecc71"
                    break;
                case 'Very strong':
                    return "#27ae60"
                    break;
                case 'Stranger':
                    return "#27ae60"
                    break;
                default:
                    return "#95a5a6"
            }
        },
    }
}
</script>
