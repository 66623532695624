import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"inline"}},[_c(VMenu,{attrs:{"transition":"slide-y-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,[_c(VListItem,{attrs:{"link":""},on:{"click":_vm.openModalMoveSecrets}},[_c(VListItemTitle,[_vm._v("Mover")])],1)],1)],1),_c(VDialog,{attrs:{"width":"auto","scrollable":""},model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}},[_c('move-password-modal',{attrs:{"secret":_vm.secret},on:{"close":_vm.closeModalMoveSecrets}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }