<template>
    <validation-provider tag="div" :name="label" :rules="rules" v-slot="{ errors }" :vid="vid">
        <v-textarea
            :rows="rows"
            :error-messages="errors"
            :label="label"
            :placeholder="placeholder"
            outlined
            v-bind="$attrs"
            v-model="innerValue"
            v-on="$listeners"
        />
    </validation-provider>
</template>

<script>
export default {
    props: {
        rows: { type: Number, default: 4 },
        placeholder: { type: String, default: '' },
        label: { type: String, default: '' },
        rules: { type: [Object, String], default: '' },
        value: { type: null },
        vid: { type: String, default: '' }
    },
    data: () => ({
        innerValue: ''
    }),
    watch: {
        innerValue(newVal) {
            this.$emit('input', newVal)
        },
        value(newVal) {
            this.innerValue = newVal
        }
    },
    created() {
        if (this.value) this.innerValue = this.value
    }
}
</script>
