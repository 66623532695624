<template>
    <div class="otp-component pa-4">
        <div class="otp-component__card mx-auto" flat>
            <div class="otp-component__content white pa-10">
                <span class="d-block text-center subtitle-2 mb-2">Ingresa tu pin de 4 digítos</span>
                <v-otp-input
                    max-width="40"
                    length="4"
                    type="password"
                    v-model="otpField"
                    @change="$emit('input', value)"
                    @finish="$emit('validateOTP')"
                />
                <v-overlay absolute :value="loading">
                    <v-progress-circular indeterminate color="primary" />
                </v-overlay>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: { require: true },
        loading: { type: Boolean, default: false }
    },
    data() {
        return {
            otpField: this.value
        }
    },
    watch: {
        otpField(value) {
            this.$emit('input', value)
        },
        value(value) {
            this.otpField = value
        }
    }
}
</script>

<style lang="scss">
.otp-component {
    align-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
    &__card {
        position: relative;
        max-width: 300px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__content {
        border-radius: 10px;
    }
}
</style>
