<template>
    <div style="display: inline;">
        <v-menu transition="slide-y-transition" offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="mx-1">
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item link @click="openModalMoveSecrets">
                    <v-list-item-title>Mover</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog v-model="openModal" width="auto" scrollable>
            <move-password-modal :secret="secret" @close="closeModalMoveSecrets"/>
        </v-dialog>
    </div>
</template>

<script>
import MovePasswordModal from '@/components/password/MovePasswordModal.vue'

export default {
    components: {
        MovePasswordModal
    },
    props: {
        secret: { type: Object, default: () => ({}), required: true },
    },
    data: () => ({
        openModal: false
    }),
    methods: {
        openModalMoveSecrets() {
            this.newWorkspace = null
            this.openModal = true
        },
        closeModalMoveSecrets() {
            this.openModal = false
        }
    }
}
</script>
