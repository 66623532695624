import service from '@/plugins/services'
import store from '@/store'

export default {
    namespaced: true,
    state: {
        loadView: false,
        logo: '',
        logos: [],
        snackbar: {
            visible: false,
            color: 'success',
            text: null,
            timeout: 2000
        }
    },
    mutations: {
        SET_LOADVIEW(state, payload) {
            state.loadView = payload
        },
        SET_LOGO(state, payload) {
            state.logo = payload
        },
        SET_LOGOS(state, payload) {
            state.logos = payload
        },
        ALERT(state, payload) {
            state.snackbar.text = payload.text
            if (payload.color) state.snackbar.color = payload.color
            if (payload.timeout) state.snackbar.timeout = payload.timeout
            state.snackbar.visible = true
        },
        CLOSE_ALERT(state) {
            state.snackbar.visible = false
            state.snackbar.multiline = false
            state.snackbar.text = null
        },
        ERROR_HANDLER(state, payload) {
            const errors = {
                ACCESS_AUTH_DENAIN: 'Acceso denegado',
                ITS_REQUIRE_A_NAME: '',
                ITS_REQUIRE_AN_EMAIL: '',
                ITS_REQUIRE_AN_EMAIL: '',
                ITS_REQUIRE_AN_USER: '',
                USER_NOT_AVALIABLED: '',
                ITS_REQUIRE_A_PASSWORD: ''
            }
            store.commit('resource/ALERT', { color: 'error', text: errors[payload], visible: true })
        }
    },
    actions: {
        async getLogos({ commit }) {
            try {
                const response = await service.get('autocompleates')
                commit('SET_LOGOS', response.data.data)
            } catch (error) {}
        }
    }
}
