<template>
    <v-card width="600">
        <v-card-title class="mb-4">
            <span>Mover secret "{{ secret.name }}" a otro workspace</span>
            <v-spacer />
            <v-btn icon @click="closeModalMoveSecrets">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <span class="d-block mb-4">
                Workspace actual: {{ secret.workSpaces.name }}
            </span>
            <v-spacer />
            <v-select
                :items="workspaces" 
                label="Selecciona un workspace"
                outlined
                item-text="name"
                item-value="uuid"
                return-object
                single-line
                :value="newWorkspace"
                @change="selectWorkspace"
            >
            </v-select>
        </v-card-text>
        <v-card-actions>
            <v-btn :loading="loading" block color="primary" elevation="0" :disabled="invalid" @click="move">Mover</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

export default {
    props: {
        secret: { type: Object, default: () => ({}), required: true },
    },
    data: () => ({
        newWorkspace: null,
        loading: false
    }),
    computed: {
        workspaces() {
            return this.$store.state.authentication.workspaces.filter(ws => {
                return ws.uuid != this.secret.workSpaces.uuid;
            })
        },
        invalid() {
            return !this.newWorkspace || this.loading
        }
    },
    methods: {
        async move() {
            try {
                this.loading = true
                await this.$axios.put(`/secrets/${this.secret.uuid}/workspaces/${this.newWorkspace.uuid}`)
                await this.$store.dispatch('password/getPasswords', { workspace: this.secret.workSpaces.uuid })
                this.$store.commit('resource/ALERT', { color: 'success', text: 'Se ha movido el secret', visible: true })
                this.closeModalMoveSecrets()
            } catch (error) {
                console.log(error)
                this.$store.commit('resource/ALERT', { color: 'error', text: 'Ocurrió un error', visible: true })
            } finally {
                this.loading = false
            }
        },
        selectWorkspace(ws) {
            this.newWorkspace = ws ?? null
        },
        closeModalMoveSecrets() {
            this.newWorkspace = null
            this.$emit('close')
        }
    }
}
</script>
