import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"login-component mx-auto",attrs:{"max-width":"500"}},[_c(VCardTitle,{staticClass:"login-component__title primary"},[_c(VImg,{attrs:{"max-width":"250","src":require("@/assets/images/logo-white.svg")}}),_c('span',{staticClass:"block white--text pb-4"},[_vm._v("Registrar usuario")])],1),_c(VCardText,[_c('validation-observer',{ref:"observer",staticClass:"px-10 pt-10",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c(VForm,{staticClass:"login-component__form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('resource-input',{attrs:{"label":"Nombre","rules":"required","type":"text","clearable":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('resource-input',{attrs:{"label":"Usuario","rules":"required","type":"text","clearable":""},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),_c('resource-input',{attrs:{"label":"Email","rules":"required|email","type":"email","clearable":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('resource-input',{attrs:{"label":"Contraseña","rules":"required","type":"password","clearable":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VBtn,{attrs:{"block":"","color":"primary","elevation":"0","type":"submit","x-large":"","disabled":invalid}},[_vm._v("Registrar usuario")])],1),_c(VBtn,{staticClass:"mt-2",attrs:{"block":"","color":"primary","text":"","elevation":"0","x-large":""},on:{"click":_vm.authHandler}},[_vm._v("Iniciar sesión")])]}}])})],1),_c('div',{staticClass:"primary d-flex justify-center py-2"},[_c('span',{staticClass:"white--text text-caption font-weight-bold"},[_vm._v("KEEP PASSWORD BETA v0.0.1")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }