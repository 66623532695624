<template>
    <v-card max-width="500" class="login-component mx-auto">
        <v-card-title class="login-component__title primary">
            <v-img max-width="250" src="@/assets/images/logo-white.svg" />
            <span class="block white--text pb-4">Iniciar sesión</span>
        </v-card-title>
        <v-card-text>
            <validation-observer ref="observer" v-slot="{ invalid }" tag="div" class="px-10 pt-10">
                <v-form @submit.prevent="submit" class="login-component__form">
                    <resource-input label="Usuario" rules="required" type="text" clearable v-model="user" />
                    <resource-input label="Contraseña" rules="required" type="password" clearable v-model="password" />
                    <v-btn block color="primary" elevation="0" type="submit" x-large :disabled="invalid">Iniciar sesión</v-btn>
                </v-form>
                <v-btn class="mt-2" block color="primary" text elevation="0" x-large @click="authHandler">Registro</v-btn>
            </validation-observer>
        </v-card-text>
        <div class="primary d-flex justify-center py-2">
            <span class="white--text text-caption font-weight-bold">KEEP PASSWORD BETA v0.0.1</span>
        </div>
    </v-card>
</template>

<script>
import ResourceInput from '@/components/resource/Input'

export default {
    components: {
        ResourceInput
    },
    data() {
        return {
            user: 'hola',
            password: '123456'
        }
    },
    methods: {
        async submit() {
            try {
                const payload = {
                    user: this.user,
                    password: this.password
                }
                await this.$store.dispatch('authentication/singIn', payload)
            } catch (error) {
                console.log(error)
            }
        },
        authHandler() {
            this.$store.commit('authentication/AUTH_HANDLER', false)
        }
    }
}
</script>

<style lang="scss">
.login-component {
    &__title {
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        padding-top: 40px;
        text-transform: uppercase;
    }
    &__form {
        display: grid;
        gap: 10px;
    }
}
</style>
