<template>
    <v-text-field v-model="search" color="primary" dense hide-details label="Buscar passwords" outlined @keyup="$emit('passwords')">
        <template #append>
            <v-icon @click="search ? $emit('clear') : $emit('passwords')" class="password-view__search--actions">{{
                search ? 'mdi-close' : 'mdi-magnify'
            }}</v-icon>
        </template>
    </v-text-field>
</template>

<script>
export default {
    props: {
        value: { require: true }
    },
    data() {
        return {
            search: this.value
        }
    },
    watch: {
        search(value) {
            this.$emit('input', value)
        },
        value(value) {
            this.search = value
        }
    }
}
</script>
