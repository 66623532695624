import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                white: '#FFFFFF',
                black: '#000000',
                primary: '#521262',
                hover: '#d6c6d9',
                secondary: '#761A8D',
                accent: '#E6E6E6'
            }
        }
    }
})
