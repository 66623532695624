<template>
    <v-card width="600">
        <v-card-text class="pa-10">
            <span class="d-block mb-2">Configuracion AWS</span>
            <span class="d-block mb-8">
                Se requiere esta configuración para que todos tus archivos
                y claves sean almacenadas en tu propia infraestructura.
            </span>
            <validation-observer ref="observer" v-slot="{ invalid }">
                <v-form @submit.prevent="submit" class="form-password__content">
                    <resource-input dense label="Bucket private" rules="required" type="text" clearable v-model="bucket_private" />
                    <resource-input dense label="Bucket private zona" rules="required" type="text" clearable v-model="bucket_private_zone" />
                    <resource-input dense label="Bucket files" rules="required" type="text" clearable v-model="bucket_files" />
                    <resource-input dense label="Bucket files zona" rules="required" type="text" clearable v-model="bucket_files_zone" />
                    <resource-input dense label="Bucket environments" rules="required" type="text" clearable v-model="bucket_environments" />
                    <resource-input dense label="Bucket environments zona" rules="required" type="text" clearable v-model="bucket_environments_zone" />
                    <resource-input dense label="Clave secreta" rules="required" type="password" clearable v-model="secret_key" />
                    <resource-input dense label="Id de acceso" rules="required" type="password" clearable v-model="access_id" />
                </v-form>
                <v-btn block color="primary" elevation="0" @click="submit" :disabled="invalid">Guardar cambios</v-btn>
            </validation-observer>
        </v-card-text>
    </v-card>
</template>

<script>
import ResourceInput from '@/components/resource/Input.vue'

export default {
    components: {
        ResourceInput
    },
    props: {
        data: { type: Object, default: () => {} }
    },
    data() {
        return {
            bucket_private: this.data?.bucket,
            bucket_private_zone: this.data?.bucket_private_zone,
            bucket_files: this.data?.bucket_files,
            bucket_files_zone: this.data?.bucket_files_zone,
            secret_key: this.data?.secret_key,
            access_id: this.data?.access_id,
            bucket_environments: this.data?.bucket_environments,
            bucket_environments_zone: this.data?.bucket_environments_zone,
        }
    },
    methods: {
        async submit() {
            try {
                const payload = {
                    access_id: this.access_id,
                    secret_key: this.secret_key,
                    bucket: this.bucket_private,
                    zone: this.bucket_private_zone,
                    bucketFiles: this.bucket_files,
                    zoneBucketFiles: this.bucket_files_zone,
                    bucketEnviroments: this.bucket_environments,
                    zoneBucketEnviroments: this.bucket_environments_zone
                }
                const response = await this.$axios.put('system/settings', payload)
                if (response) {
                    this.$store.commit('resource/ALERT', {
                        color: 'success',
                        text: `Configuracion AWS almacenada correctamente 👍`,
                        visible: true
                    })
                    this.$store.commit('authentication/SET_IS_CONFIG_CLOUD_ACCOUNT', true)
                    this.$emit('getSetting')
                } else console.log('Error al actualizar')
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>
