<template>
    <div class="login-container accent">
        <div class="login-container__box">
            <login-component v-if="authHandler" />
            <register-component v-else />
        </div>
        <v-snackbar v-model="snackbar.visible" :timeout="snackbar.timeout" :color="snackbar.color" top right>
            <div class="d-flex align-center justify-space-between">
                <span class="d-block font-weight-medium">{{ snackbar.text }}</span>
                <v-btn small icon @click="closeAlert">
                    <v-icon small>mdi-close</v-icon>
                </v-btn>
            </div>
        </v-snackbar>
    </div>
</template>

<script>
import LoginComponent from '@/components/authentication/Login.vue'
import RegisterComponent from '@/components/authentication/Register.vue'

export default {
    name: 'AuthenticationView',
    components: {
        LoginComponent,
        RegisterComponent
    },
    computed: {
        authHandler() {
            return this.$store.state.authentication.authHandler
        },
        snackbar() {
            return this.$store.state.resource.snackbar
        }
    },
    methods: {
        closeAlert() {
            this.$store.commit('resource/CLOSE_ALERT')
        }
    }
}
</script>

<style lang="scss" scoped>
.login-container {
    height: 100%;
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
</style>
