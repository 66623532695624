<template>
    <default-layout>
        <div class="password-view">
            <div class="password-view__containter">
                <div class="password-view__passwords px-4">
                    <password-search v-model="search" @clear="clearSearch" @passwords="searchPassword" />
                    <template v-if="passwords.length">
                        <div class="d-flex justify-end mb-2">
                            <v-btn @click="changeGrid" color="blue-grey" dark depressed fab x-small>
                                <v-icon dark>{{ isGrid ? 'mdi-view-grid-outline' : 'mdi-menu' }}</v-icon>
                            </v-btn>
                        </div>
                        <div :class="isGrid ? 'password-view__list' : 'password-view__grid'">
                            <password-item
                                v-for="(secret, index) in passwords"
                                :key="index"
                                :isLocked="isLocked === secret.uuid"
                                :secret="secret"
                                :isGrid="isGrid"
                                @unlock="unlock($event)"
                            />
                        </div>
                    </template>
                    <template v-else>
                        <div class="password-view__empty-password">
                            <v-img max-width="300" class="mt-10 mx-auto" src="@/assets/images/empty.svg" alt="empty password" />
                            <span class="d-block text-center subtitle-1 mt-10">No encontramos passwords en este workspace</span>
                        </div>
                    </template>
                </div>
                <v-divider vertical class="password-view__divider" />
                <div class="password-view__detail py-4 px-10">
                    <template v-if="!passwordType && !showDetail">
                        <div class="password-view__empty">
                            <img width="500" src="@/assets/images/logo-placeholder.svg" />
                        </div>
                    </template>
                    <password-form v-if="passwordType" :data="passwordData" />
                    <template v-if="showDetail">
                        <password-validation
                            v-if="requiredPassword"
                            :loading="loadOTP"
                            @validateOTP="validateOTP"
                            v-model="oneTimePassword"
                        />
                        <password-detail
                            v-else
                            :detail="detail"
                            :labels="labels"
                            :loading="loading"
                            :secret="secret"
                            :timeFormart="timeFormart"
                            :errorView="errorView"
                            @copyData="copyData"
                            @editPassword="editPassword"
                            @close="resetData"
                        />
                    </template>
                    <resource-button v-else fixed right buttom text="Nuevo" @action="showNewPasswordForm" />
                </div>
            </div>
        </div>
    </default-layout>
</template>

<script>
import DefaultLayout from '@/components/layout/Default.vue'
import PasswordItem from '@/components/password/Item.vue'
import PasswordDetail from '@/components/password/Detail.vue'
import PasswordValidation from '@/components/password/Validation.vue'
import PasswordSearch from '@/components/password/Search.vue'
import PasswordForm from '@/components/password/Form.vue'
import ResourceButton from '@/components/resource/Button.vue'

export default {
    components: {
        DefaultLayout,
        PasswordDetail,
        PasswordItem,
        PasswordValidation,
        PasswordSearch,
        PasswordForm,
        ResourceButton
    },
    data() {
        return {
            loading: false,
            isGrid: false,
            loadOTP: false,
            oneTimePassword: '',
            password: '',
            requiredPassword: false,
            errorView: false,
            search: '',
            showDetail: false,
            timeLimit: 90,
            timePassed: 0,
            timerInterval: null,
            detail: {
                user: '********',
                password: '********',
                linkWeb: '********'
            },
            passwordData: null
        }
    },
    async mounted() {
        await this.$store.dispatch('authentication/getWorkspaces')
        await this.getPasswords()
    },
    watch: {
        timePassed(value) {
            if (value === this.timeLimit) this.resetData()
        },
        search() {
            if (this.search.length > 3) this.$store.commit('password/SET_FILTER', this.search)
            else this.$store.commit('password/SET_FILTER', null)
        }
    },
    computed: {
        isLocked() {
            return this.$store.state.password.isLocked
        },
        passwordType() {
            return this.$store.state.password.password
        },
        passwords() {
            return this.$store.state.password.passwords
        },
        timeFormart() {
            const timeLeft = this.timeLimit - this.timePassed
            const minutes = Math.floor(timeLeft / 60)
            let seconds = timeLeft % 60
            if (seconds < 10) seconds = `0${seconds}`
            return `${minutes}:${seconds}`
        },
        labels() {
            return [
                { label: 'Usuario', value: 'user', show: false },
                { label: 'Contraseña', value: 'password', show: false },
                { label: 'Enlace web', value: 'linkWeb', show: false }
            ]
        }
    },
    methods: {
        startTimer() {
            this.timerInterval = setInterval(() => this.timePassed++, 1000)
        },
        async searchPassword() {
            const name = this.$store.state.authentication.workspace
            const uuid = this.$store.state.authentication.uuid
            await this.passwordHandler({ name, uuid })
        },
        async getPasswords() {
            const { uuid, name } = this.$store.state.authentication.workspaces.find((workspace) => workspace.name === 'DEFAULT')
            await this.passwordHandler({ name, uuid })
        },
        async passwordHandler({ name, uuid }) {
            this.$store.commit('authentication/SET_WORKSPACE', name)
            this.$store.commit('authentication/SET_UUID', uuid)
            await this.$store.dispatch('password/getPasswords', { workspace: uuid })
        },
        async viewPassword(uuid) {
            try {
                this.loading = true
                const response = await this.$axios.get(`secrets/${uuid}/decrypt`)
                if (response.data) {
                    window.clearInterval(this.timerInterval)
                    this.detail = response.data
                    this.errorView = false
                    this.startTimer()
                }
            } catch (error) {
                this.errorView = true
            } finally {
                this.loading = false
            }
        },
        copyData(data) {
            const value = this.labels.find((item) => item.label === data.label)
            value.show = true
            navigator.clipboard.writeText(data.value)
            if (value.show)
                setTimeout(() => {
                    value.show = false
                }, 1500)
        },
        unlock(secret) {
            this.resetData()
            this.loading = true
            this.$store.commit('password/SET_TYPE_PASSWORD', '')
            this.requiredPassword = secret.isDoubleSecurity
            this.secret = secret
            if (secret) {
                this.$store.commit('password/SET_UNLOCK', secret.uuid)
                this.showDetail = true
                this.loading = false
            }
            if (!secret.isDoubleSecurity) this.viewPassword(secret.uuid)
            else this.loading = false
        },
        validateOTP() {
            this.loadOTP = true
            setTimeout(() => {
                if (this.oneTimePassword === '1234') {
                    this.viewPassword(this.secret.uuid)
                    this.requiredPassword = false
                    this.loadOTP = false
                } else {
                    this.oneTimePassword = ''
                    this.loadOTP = false
                }
            }, 1000)
        },
        async editPassword(secret) {
            const response = await this.$axios.get(`secrets/${secret}/decrypt`)
            this.passwordData = { ...response.data, uuid: this.secret.uuid }
            try {
                await this.$store.dispatch('resource/getLogos')
            } catch (error) {
                console.log(error)
            }
            this.$store.commit('password/SET_TYPE_PASSWORD', 'PUT')
            this.showDetail = false
        },
        resetData() {
            window.clearInterval(this.timerInterval)
            this.detail = {}
            this.$store.commit('password/SET_UNLOCK', null)
            this.loading = false
            this.oneTimePassword = ''
            this.showDetail = false
            this.timeLimit = 90
            this.timePassed = 0
            this.timerInterval = null
        },
        async clearSearch() {
            this.search = ''
            this.$store.commit('password/SET_FILTER', this.search)
            await this.$store.dispatch('password/getPasswords', { workspace: this.$store.state.authentication.uuid })
        },
        showNewPasswordForm() {
            this.$store.commit('password/SET_TYPE_PASSWORD', 'POST')
            this.showDetail = false
        },
        changeGrid() {
            this.isGrid = !this.isGrid
        }
    },
    beforeDestroy() {
        window.clearInterval(this.timerInterval)
    }
}
</script>

<style lang="scss">
.password-view {
    &__containter {
        align-items: flex-start;
        display: grid;
        grid-template-columns: 600px 1px 1fr;
    }
    &__passwords {
        display: grid;
        gap: 8px;
        position: sticky;
        top: 85px;
    }
    &__grid {
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(3, 1fr);
    }
    &__divider {
        height: 100vh;
    }
    &__search {
        width: 100%;
        max-width: 600px;
        &--actions {
            cursor: pointer;
        }
    }
    &__empty-password {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__empty {
        position: absolute;
        top: 40%;
        left: 50%;
    }
}
</style>
