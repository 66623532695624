<template>
    <v-card width="600">
        <v-card-title class="text-uppercase mb-4">
            <span>Compartir secret</span>
            <v-spacer />
            <v-btn small icon @click="resetData">
                <v-icon small>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <validation-observer ref="emailObserver" v-slot="{ invalidEmail }">
                <p class="mb-2">Agrega los correos de las personas con las que quieres compartir tu secret</p>
                <form @submit="pushEmail">
                    <div class="d-flex align-baseline">
                        <resource-input
                            class="emails-add__input mr-2"
                            label="Correo electrónico"
                            rules="email"
                            type="email"
                            clearable
                            v-model="email"
                            dense
                        />
                        <v-btn type="submit" color="primary" small fab>
                            <v-icon>mdi-account-plus</v-icon>
                        </v-btn>
                    </div>
                </form>
            </validation-observer>
            <validation-observer ref="observer" v-slot="{ invalid }">
                <div class="mb-4" v-if="emails.length > 0">
                    <h4 class="mb-2 text-uppercase">Compartiendo con:</h4>
                    <div v-for="(data, index) in emails">
                        <v-chip label class="mr-2 mb-2">
                            <v-icon left small>mdi-account</v-icon>
                            <span class="d-block mr-4">{{ data.email }}</span>
                            <v-btn small icon @click="removeEmail(index)">
                                <v-icon small>mdi-close</v-icon>
                            </v-btn>
                        </v-chip>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="form-password__password-generate mr-4">
                        <resource-input
                            dense
                            label="Clave maestra"
                            type="password"
                            rules="required"
                            clearable 
                            v-model="password"
                        />
                    </div>
                    <v-tooltip left color="primary" v-model="showCopyPassword">
                        <template v-slot:activator="{ on }">
                            <v-btn class="mr-4" depressed @click="copyPasswordGenerate" icon>
                                <v-icon small>mdi-content-copy</v-icon>
                            </v-btn>
                        </template>
                        <span v-if="showCopyPassword">Contraseña copiada</span>
                    </v-tooltip>
                    <v-tooltip left color="primary" v-model="showGeneratePassword">
                        <template v-slot:activator="{ on }">
                            <v-btn depressed icon @click="generatePassword">
                                <v-icon small>mdi-lock</v-icon>
                            </v-btn>
                        </template>
                        <span v-if="showGeneratePassword">
                            Contraseña generada correctamente
                        </span>
                    </v-tooltip>
                </div>
                <div class="d-flex" style="gap: 16px;">
                    <resource-input class="w-full" rules="required" dense label="Tiempo de vencimiento del secret" type="number" clearable v-model="timeLimit" />
                    <v-select
                        :items="types"
                        outlined
                        item-text="label"
                        item-value="value"
                        single-line
                        :value="type"
                        @change="selectType"
                        dense
                    >
                    </v-select>
                </div>
                <resource-input class="w-full" rules="required" dense label="Máximo de visualizaciones" type="number" clearable v-model="countOpen" />
            </validation-observer>
            <span class="red--text mb-4" v-if="showMissingEmails">
                Agrega al menos una persona para compartir
            </span>
            <v-btn color="primary" block @click="submitShare">
                Compartir
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import ResourceInput from '@/components/resource/Input.vue'

export default {
    components: {
        ResourceInput
    },
    data() {
        return {
            email: "",
            password: "",
            emails: [],
            timeLimit: 8,
            type: "hours",
            countOpen: 2,
            showGeneratePassword: false,
            showCopyPassword: false,
            showMissingEmails: false,
            types: [
                { label: "Horas", value: "hours" },
                { label: "Días", value: "days" },
            ]
        }
    },
    props: {
        secret: { type: Object, required: true },
    },
    methods: {
        async submitShare() {
            this.showMissingEmails = false
            const validate = await this.$refs.observer.validate()
            if (!validate) return
            if (this.emails.length < 1) {
                this.showMissingEmails = true
                return
            }
            try {
                const body = {
                    code:this.password,
                    emails: this.emails.map(e => e.email),
                    timeLimit: this.timeLimit,
                    type: this.type,
                    countOpen: this.countOpen
                }
                const uuid = this.secret.uuid
                await this.$axios.post(`/secrets/${uuid}/shared`, body)
                this.$store.commit('resource/ALERT', {
                    color: 'success',
                    text: `Se ha enviado un link para compartir el secret`,
                    visible: true 
                })
                this.resetData()
            } catch (error) {
                console.error(error)
                this.$store.commit('resource/ALERT', {
                    color: 'error',
                    text: `Ocurrió un error`,
                    visible: true
                })
            }
        },
        async pushEmail(e) {
            e.preventDefault();
            const validate = await this.$refs.emailObserver.validate()
            if (validate && this.email) this.emails.push({ email: this.email })
            this.email = null
        },
        removeEmail(index) {
            if (index > -1) this.emails.splice(index, 1)
        },
        resetData() {
            this.password = ""
            this.email = ""
            this.timeLimit = 8
            this.type = "hours"
            this.countOpen = 2
            this.emails = []
            this.$refs.observer.reset();
            this.$emit('close')
        },
        async generatePassword() {
            const response = await this.$axios.get('passwords')
            this.password = response.data.data?.password
            this.showGeneratePassword = true
            if (this.showGeneratePassword) {
                setTimeout(() => {
                    this.showGeneratePassword = false
                }, 1500)
            }
        },
        async copyPasswordGenerate() {
            navigator.clipboard.writeText(this.password)
            this.showCopyPassword = true
            if (this.showCopyPassword) {
                setTimeout(() => {
                    this.showCopyPassword = false
                }, 1500)
            }
        },
        selectType(value) {
            this.type = value;
        }
    }
}
</script>

<style lang="scss">
.emails-add {
    &__input {
        width: 100%;
    }
}
</style>

