import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import AuthView from '../views/home/index.vue'
import PasswordView from '../views/password/index.vue'
import SettingView from '../views/setting/index.vue'

Vue.use(VueRouter)

const routes = [
    {
        component: AuthView,
        name: 'auth',
        path: '/',
        meta: { requiresAuth: false }
    },
    {
        component: PasswordView,
        name: 'password',
        path: '/password',
        meta: { requiresAuth: true }
    },
    {
        component: SettingView,
        name: 'setting',
        path: '/setting',
        meta: { requiresAuth: true }
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

// router.beforeEach((to, from, next) => {
//     const isAuthenticated = store.getters['authentication/isAuthenticated']
//     if (to.matched.some((record) => record.meta.requiresAuth)) {
//         if (!isAuthenticated) next({ name: 'auth' })
//         else next()
//     } else next({ name: 'password' })
// })

export default router
