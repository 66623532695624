<template>
    <v-card class="form-password">
        <v-card-title class="text-uppercase justify-space-between">
            <span>{{ passwordType === 'POST' ? 'Nuevo ' : 'Editar ' }}password</span>
            <v-btn small fab text @click="closeForm">
                <v-icon color="gray">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <validation-observer ref="observer" v-slot="{ invalid }">
                <v-form @submit.prevent="submit" class="form-password__content" enctype="multipart/form-data">
                    <v-combobox
                        label="Nombre del servicio"
                        hide-selected
                        clearable
                        outlined
                        dense
                        item-text="name"
                        item-value="icon"
                        return-object
                        :items="logos"
                        v-model="autocomplete"
                        @change="changeLogoForText(autocomplete)"
                    >
                        <template #selection="data">
                            <v-chip
                                outlined
                                label
                                v-bind="data.attrs"
                                :model-value="data.selected"
                                :disabled="data.disabled"
                                size="small"
                                @click:close="data.parent.selectItem(data.item)"
                            >
                                <v-avatar left>
                                    <v-img :src="data.item.icon || placeholder" />
                                </v-avatar>
                                <span class="text-uppercase font-weight-bold text-subtitle-2">{{ data.item.name || data.item }}</span>
                            </v-chip>
                        </template>
                        <template #item="data">
                            <template v-if="data.item">
                                <v-list-item-avatar v-if="data.item.icon">
                                    <v-img width="10" :src="data.item.icon" />
                                </v-list-item-avatar>
                                <v-list-item-content
                                    class="text-uppercase font-weight-bold text-subtitle-2"
                                    v-text="data.item.name || data.item"
                                />
                            </template>
                        </template>
                        <template #no-data>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        No encontramos resultados para el servicio, presiona <kbd>enter</kbd> para crearlo.
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-combobox>
                    <!-- <resource-color
                        v-model="color"
                        :selected="color"
                        :viewColorPicker="viewColorPicker"
                        @hide="hideColorPicker"
                        @show="showColorPicker"
                    /> -->
                    <!-- <v-switch v-model="isDoubleSecurity" label="¿Requerir segundo factor de autenticacion?" /> -->
                    <resource-input dense label="Enlace web" rules="required" type="text" clearable v-model="linkWeb" />
                    <resource-input dense label="Usuario" rules="required" type="text" clearable v-model="user" />
                    <div class="d-flex">
                        <div class="form-password__password-generate mr-4">
                            <resource-input dense label="Contraseña" type="password" clearable v-model="password" />
                        </div>
                        <v-tooltip left color="primary" v-model="showCopyPassword">
                            <template v-slot:activator="{ on }">
                                <v-btn class="mr-4" depressed @click="copyPasswordGenerate" icon>
                                    <v-icon small>mdi-content-copy</v-icon>
                                </v-btn>
                            </template>
                            <span v-if="showCopyPassword">Contraseña copiada</span>
                        </v-tooltip>
                        <v-tooltip left color="primary" v-model="showGeneratePassword">
                            <template v-slot:activator="{ on }">
                                <v-btn depressed icon @click="generatePassword">
                                    <v-icon small>mdi-lock</v-icon>
                                </v-btn>
                            </template>
                            <span v-if="showGeneratePassword">Contraseña generada correctamente</span>
                        </v-tooltip>
                    </div>
                    <v-combobox multiple small-chips v-model="tags" outlined dense label="Etiquetas" />
                    <v-file-input
                        clearable
                        color="primary"
                        counter
                        dense
                        small-chips
                        multiple
                        label="Agregar documentos"
                        outlined
                        prepend-icon=""
                        v-model="files"
                    >
                        <template v-slot:selection="{ text, index, file }">
                            <v-chip small close @click:close="fileRemove(index)">{{ text }}</v-chip>
                        </template>
                        <template #append>
                            <v-icon>mdi-paperclip</v-icon>
                        </template>
                    </v-file-input>
                    <div class="mb-4">
                        <span class="d-block mb-2">Agregar notas</span>
                        <v-md-editor v-model="notes" height="300px" mode="edit"
                        left-toolbar="h bold italic strikethrough quote | ul ol table hr | link image code"
                        right-toolbar="preview fullscreen"
                        />
                    </div>
                    <v-btn class="mb-5" block depressed @click="showFieldCustom">
                        <v-icon small left>mdi-plus</v-icon>
                        <span>Campos personalizados</span>
                    </v-btn>
                    <div class="form-password__add-fields pb-10" v-if="fieldCustom">
                        <v-text-field hide-details filled dense label="Nombre del campo" v-model="fieldItem.label" />
                        <v-text-field hide-details filled dense label="Valor" v-model="fieldItem.value" />
                        <v-switch class="mt-0" hide-details label="Ocultar valor" v-model="fieldItem.type" />
                        <v-btn elevation="0" @click="addCustomField" color="primary">Agregar</v-btn>
                    </div>
                    <div class="form-password__fields d-flex align-center pb-5" v-for="(field, index) in fields">
                        <resource-input
                            :hide-details="true"
                            :label="field.label"
                            :type="field.type"
                            v-model="field.value"
                            class="form-password__fields--input mr-2"
                            clearable
                            dense
                        />
                        <v-btn fab x-small icon @click="removeFieldCustom(index)">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </v-form>
                <v-btn block color="primary" elevation="0" @click="submit" :disabled="invalid"
                    >{{ passwordType === 'POST' ? 'Crear ' : 'Editar ' }}password</v-btn
                >
            </validation-observer>
        </v-card-text>
    </v-card>
</template>

<script>
import ResourceInput from '@/components/resource/Input.vue'
import ResourceTextarea from '@/components/resource/Textarea.vue'
import ResourceColor from '@/components/resource/Color.vue'

export default {
    components: { ResourceColor, ResourceInput, ResourceTextarea },
    props: {
        data: { type: Object, default: () => {} }
    },
    data() {
        return {
            autocomplete: '',
            colaborators: [],
            color: '#FFFFFF',
            fieldCustom: false,
            fieldItem: { label: '', value: '', type: '' },
            fields: [],
            files: [],
            isDoubleSecurity: false,
            linkWeb: '',
            logo: '',
            name: '',
            notes: '',
            password: '',
            showCopyPassword: false,
            showGeneratePassword: false,
            tags: [],
            user: '',
            viewColorPicker: false,
            workSpaces: {}
        }
    },
    computed: {
        passwordType() {
            return this.$store.state.password.password
        },
        logos() {
            return this.$store.state.resource.logos
        },
        placeholder() {
            return require('@/assets/images/placeholder.jpeg')
        },
        icon() {
            const logo = this.logos.find((icon) => icon.name === this.data?.name)
            return logo?.icon
        }
    },
    async created() {
        if (this.passwordType === 'PUT') {
            this.autocomplete = { name: this.data?.name, icon: this.icon }
            this.colaborators = this.data?.colaborators
            this.color = '#FFFFFF'
            this.fields = this.data?.fields || undefined
            this.isDoubleSecurity = this.data?.isDoubleSecurity
            this.linkWeb = this.data?.linkWeb
            this.logo = this.data?.logo
            this.name = this.data?.name
            this.notes = this.data?.notes
            this.password = this.data?.password
            this.tags = this.data?.tags
            this.user = this.data?.user
            this.workSpaces = this.data?.workSpaces
        }
        try {
            await this.$store.dispatch('resource/getLogos')
        } catch (error) {
            console.log(error)
        }
    },
    methods: {
        async submit() {
            this.workSpaces = {
                name: this.$store.state.authentication.workspace,
                uuid: this.$store.state.authentication.uuid
            }
            try {
                let data = new FormData()

                for (let file of this.files) {
                    data.append('files', file)
                }

                data.append('colaborators', this.colaborators)
                data.append('color', this.color)
                data.append('fields', JSON.stringify(this.fields))
                data.append('isDoubleSecurity', this.isDoubleSecurity)
                data.append('linkWeb', this.linkWeb)
                data.append('logo', this.autocomplete?.icon)
                data.append('name', this.name)
                data.append('notes', this.notes)
                data.append('password', this.password)
                data.append('tags', JSON.stringify(this.tags))
                data.append('user', this.user)
                data.append('workSpaces', JSON.stringify(this.workSpaces))

                const headers = { 'Content-Type': 'multipart/form-data' }
                if (this.passwordType === 'POST') {
                    await this.$axios.post('secrets', data, headers)
                    this.$store.commit('resource/ALERT', { color: 'success', text: `Password creado correctamente 👍`, visible: true })
                } else {
                    await this.$axios.put(`secrets/${this.data.uuid}`, data, headers)
                    this.$store.commit('resource/ALERT', { color: 'success', text: `Password actualizado correctamente 👍`, visible: true })
                }
                await this.$store.dispatch('password/getPasswords', { workspace: this.$store.state.authentication.uuid })
                this.$store.commit('password/SET_TYPE_PASSWORD', '')
                this.$store.commit('password/SET_UNLOCK', null)
            } catch (error) {
                console.log(error)
            }
        },
        showFieldCustom() {
            this.fieldCustom = !this.fieldCustom
        },
        showColorPicker() {
            this.viewColorPicker = !this.viewColorPicker
        },
        addCustomField() {
            const type = this.fieldItem.type ? 'password' : 'text'
            const value = this.fieldItem.value.replace(/\s/g, '')
            this.fields.push({ ...this.fieldItem, type, value })
            this.fieldItem = {}
        },
        removeFieldCustom(index) {
            if (index > -1) this.fields.splice(index, 1)
        },
        hideColorPicker() {
            this.viewColorPicker = false
        },
        closeForm() {
            this.$store.commit('password/SET_TYPE_PASSWORD', '')
        },
        changeLogoForText(data) {
            if (typeof data === 'object') {
                this.name = data?.name
                this.logo = data?.icon
            } else this.name = data
        },
        async generatePassword() {
            const response = await this.$axios.get('passwords')
            this.password = response.data.data?.password
            this.showGeneratePassword = true
            if (this.showGeneratePassword) {
                setTimeout(() => {
                    this.showGeneratePassword = false
                }, 1500)
            }
        },
        async copyPasswordGenerate() {
            navigator.clipboard.writeText(this.password)
            this.showCopyPassword = true
            if (this.showCopyPassword) {
                setTimeout(() => {
                    this.showCopyPassword = false
                }, 1500)
            }
        }
    }
}
</script>

<style lang="scss">
.form-password {
    max-width: 900px;
    &__add-fields,
    &__fields {
        align-items: center;
        display: grid;
        gap: 16px;
    }
    &__add-fields {
        grid-template-columns: repeat(3, 1fr) 110px;
        justify-content: space-between;
    }
    &__fields {
        &--input {
            width: 100%;
        }
    }
    &__password-generate {
        width: 100%;
    }
    &__color-selected {
        border-radius: 4px;
        border: solid 0.5px #cecece;
        cursor: pointer;
        height: 30px;
        max-width: 100px;
        width: 100px;
    }
}
</style>
