<template>
    <v-dialog v-model="isConfigCloudAccount" @input="$emit('input', $event)" width="auto" persistent>
        <cloud-config />
    </v-dialog>
</template>

<script>
import CloudConfig from '@/components/setting/CloudConfig.vue'

export default {
    components: {
        CloudConfig
    },
    computed: {
        isConfigCloudAccount() {
            return this.$store.state.authentication.isConfigCloudAccount
        }
    }
}
</script>
