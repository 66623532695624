import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import authentication from '@/store/modules/authentication'
import password from '@/store/modules/password'
import resource from '@/store/modules/resource'

const persistent = new VuexPersistence({
    key: 'SECRETS_APP',
    storage: window.localStorage,
    reducer: (state) => ({
        authentication: {
            isConfig: state.authentication.isConfigCloudAccount,
            isAuthenticated: state.authentication.isAuthenticated,
            token: state.authentication.token,
            user: state.authentication.user,
            uuid: state.authentication.uuid,
            workspace: state.authentication.workspace,
            workspaces: state.authentication.workspaces
        },
        resource: {
            snackbar: state.resource.snackbar
        }
    })
})

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        authentication,
        password,
        resource
    },
    plugins: [persistent.plugin]
})
