<template>
    <v-app id="app" class="default-layout">
        <v-navigation-drawer app :mini-variant="mini" color="grey lighten-5">
            <div class="primary pa-4 mb-4">
                <img :src="logo" :style="{ maxWidth: (mini ? '30px' : '150px') }"/>
            </div>
            <v-list dense nav>
                <v-list-item
                    v-for="item in routes"
                    :key="item.title"
                    :to="{ name: item.routeName }"
                    active-class="primary white--text"
                    exact
                    link
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <template v-slot:append>
                <v-list dense nav>
                    <v-list-item active-class="primary white--text" exact link :to="{ name: 'setting' }">
                        <v-list-item-icon>
                            <v-icon class="light-blue-darken-1--text">mdi-cog</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="light-blue-darken-1--text">Configuración</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="logout()">
                        <v-list-item-icon>
                            <v-icon class="light-blue-darken-1--text">mdi-exit-to-app</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="light-blue-darken-1--text">Cerrar sesión</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <div class="primary caption white--text text-center">
                    <span class="d-block pa-3 font-weight-bold">{{ mini ? 'v0.0.1' : 'KEEP PASSWORD BETA v0.0.1' }}</span>
                </div>
            </template>
        </v-navigation-drawer>
        <v-app-bar elevation="1" color="white" app>
            <div class="d-flex align-center">
                <v-app-bar-nav-icon @click="mini = !mini" color="primary" />
                <v-menu offset-y transition="slide-y-transition" v-if="showNewWorkspace">
                    <template v-slot:activator="{ attrs, on }">
                        <v-btn class="justify-space-between" color="primary" block text v-bind="attrs" v-on="on">
                            <span>WORKSPACE: {{ workspaceName }}</span>
                            <v-icon color="primary" left>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-subheader @click="workspaceCreateHandler">
                            <v-btn block color="primary" elevation="0">Nuevo workspace</v-btn>
                        </v-subheader>
                        <v-divider />
                        <v-list-item v-for="(item, index) in items" :key="index" link @click="selectWorkspace(item)">
                            <v-list-item-title class="caption text-uppercase font-weight-medium" v-text="item.name" />
                        </v-list-item>
                    </v-list>
                </v-menu>
                <validation-observer v-else ref="observer" v-slot="{ invalid }" tag="div" class="default-layout__create-workspace">
                    <v-form @submit.prevent="newWorkspace" class="d-flex justify-space-between align-center">
                        <resource-input
                            class="default-layout__input"
                            label="Nuevo workspace"
                            dense
                            type="text"
                            hideDetails
                            clearable
                            v-model="workspace"
                        />
                        <v-btn v-if="workspace" color="secondary" class="ml-2" elevation="0" fab x-small :disabled="invalid" type="submit">
                            <v-icon>mdi-check-underline</v-icon>
                        </v-btn>
                        <v-btn v-else color="secondary" class="ml-2" elevation="0" fab x-small @click="workspaceCreateHandler">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-form>
                </validation-observer>
                <v-tooltip right color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            v-if="showNewWorkspace"
                            elevation="0"
                            fab
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            @click="openAddColaborator"
                        >
                            <v-icon small>mdi-account-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Agregar colaboradores</span>
                </v-tooltip>
                <v-tooltip right color="blue-grey">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-show="hasColaborators" class="ml-4" color="blue-grey" elevation="0" fab x-small v-bind="attrs" v-on="on">
                            <v-icon color="white" small>mdi-account-group</v-icon>
                        </v-btn>
                    </template>
                    <div>
                        <h4 class="text-uppercase text-caption font-weight-bold">Colaboradores:</h4>
                        <template v-for="(data, index) in colaborators">
                            <li :key="index">{{ data.email }}</li>
                        </template>
                    </div>
                </v-tooltip>
            </div>
        </v-app-bar>
        <v-snackbar v-model="snackbar.visible" :timeout="snackbar.timeout" :color="snackbar.color" top right>
            <div class="d-flex align-center justify-space-between">
                <span class="d-block font-weight-medium">{{ snackbar.text }}</span>
                <v-btn small icon @click="closeAlert">
                    <v-icon small>mdi-close</v-icon>
                </v-btn>
            </div>
        </v-snackbar>
        <cloud-config-modal />
        <v-dialog v-model="addColaborator" width="auto">
            <colaborator-add @close="closeColaborator" />
        </v-dialog>
        <slot />
    </v-app>
</template>

<script>
import ResourceInput from '@/components/resource/Input.vue'
import ColaboratorAdd from '@/components/colaborator/Add.vue'
import CloudConfigModal from '@/components/setting/CloudConfigModal.vue'

export default {
    components: {
        ResourceInput,
        ColaboratorAdd,
        CloudConfigModal
    },
    data: () => ({
        drawer: null,
        mini: false,
        addColaborator: false,
        showNewWorkspace: true,
        overlay: false,
        routes: [{ title: 'Passwords', icon: 'mdi-lock', routeName: 'password' }],
        workspace: '',
        hasColaborators: false,
        colaborators: [],
        workspaceName: 'default'
    }),
    computed: {
        snackbar() {
            return this.$store.state.resource.snackbar
        },
        items() {
            return this.$store.state.authentication.workspaces
        },
        logo() {
            if (this.mini) return require('@/assets/images/isologo-white.svg')
            return require('@/assets/images/logo-white.svg')
        }
    },
    methods: {
        async newWorkspace() {
            try {
                const response = await this.$axios.post('workspaces', { name: this.workspace })
                this.$store.state.authentication.workspaces.push(response.data.data[0])
                this.$store.commit('authentication/SET_UUID', response.data.data[0].uuid)
                this.workspace = ''
                await this.selectWorkspace(response.data.data[0])
                this.showNewWorkspace = true
            } catch (error) {
                console.log(error)
            }
        },
        openAddColaborator() {
            this.addColaborator = true
        },
        closeColaborator() {
            this.addColaborator = false
        },
        async selectWorkspace(workspace) {
            this.workspaceName = workspace.name
            try {
                this.$store.commit('resource/SET_LOADVIEW', true)
                await this.$store.dispatch('password/getPasswords', { workspace: workspace.uuid })
                this.$store.commit('authentication/SET_UUID', workspace.uuid)
                this.$store.commit('authentication/SET_WORKSPACE', workspace.name)
                const { colaborators } = this.$store.state.authentication?.workspaces.find((value) => value.uuid === workspace.uuid)
                this.colaborators = colaborators
                this.hasColaborators = colaborators.length !== 0
            } catch (error) {
                console.log(error)
            } finally {
                this.$store.commit('resource/SET_LOADVIEW', false)
            }
        },
        workspaceCreateHandler() {
            this.showNewWorkspace = !this.showNewWorkspace
        },
        closeAlert() {
            this.$store.commit('resource/CLOSE_ALERT')
        },
        logout() {
            localStorage.clear()
            this.$router.push({ name: 'auth' })
        }
    }
}
</script>

<style lang="scss">
.default-layout {
    &__input,
    &__create-workspace {
        width: 100%;
        height: 100%;
    }
}
</style>
