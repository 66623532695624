<template>
    <v-tooltip top color="primary">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                :bottom="bottom"
                :color="color"
                :fab="fab"
                :fixed="fixed"
                :right="right"
                v-bind="attrs"
                v-on="on"
                @click="$emit('action')"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </template>
        <span>{{ text }}</span>
    </v-tooltip>
</template>

<script>
export default {
    props: {
        bottom: { type: Boolean, default: true },
        color: { type: String, default: 'primary' },
        fab: { type: Boolean, default: true },
        fixed: { type: Boolean, fixed: true },
        right: { type: Boolean, fixed: true },
        text: { type: String, default: 'Nuevo' }
    }
}
</script>
