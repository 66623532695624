import Vue from "vue";
import * as rules from "vee-validate/dist/rules";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import es from "vee-validate/dist/locale/es.json";

const validation = Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize("es", es);

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

export default validation;
