import service from '@/plugins/services'
import router from '@/router'
import store from '@/store'

export default {
    namespaced: true,
    state: {
        authHandler: true,
        isAuthenticated: false,
        token: '',
        user: '',
        uuid: '',
        workspace: '',
        workspaces: [],
        isConfigCloudAccount: false
    },
    getters: {
        isAuthenticated: (state) => {
            return state.isAuthenticated
        }
    },
    mutations: {
        AUTH_HANDLER(state, payload) {
            state.authHandler = payload
        },
        SET_IS_LOGGED(state, payload) {
            state.isAuthenticated = payload
        },
        SET_USER(state, payload) {
            state.user = payload
        },
        SET_TOKEN(state, payload) {
            state.token = payload
        },
        SET_WORKSPACE(state, payload) {
            state.workspace = payload
        },
        SET_WORKSPACES(state, payload) {
            state.workspaces = payload
        },
        SET_UUID(state, payload) {
            state.uuid = payload
        },
        SET_IS_CONFIG_CLOUD_ACCOUNT(state, payload) {
            state.isConfigCloudAccount = !payload
        }
    },
    actions: {
        async singIn({ commit }, payload) {
            try {
                const { data } = await service.post('/auth', payload)
                const response = data.data
                if (response) {
                    commit('SET_IS_LOGGED', true)
                    commit('SET_USER', response.name)
                    commit('SET_TOKEN', response.token)
                    commit('SET_WORKSPACES', response.workspaces)
                    commit('SET_IS_CONFIG_CLOUD_ACCOUNT', response.isConfig)
                    await router.push({ name: 'password' })
                    store.commit('resource/ALERT', { color: 'primary', text: `Bienvenido ${response.name}  👋`, visible: true })
                }
            } catch (error) {
                commit('SET_IS_LOGGED', false)
                store.commit('resource/ERROR_HANDLER', error.response.data.msg)
            }
        },
        async singUp({ commit }, payload) {
            try {
                const { data } = await service.post('/users', payload)
                if (data.data) commit('AUTH_HANDLER', true)
            } catch (error) {
                let message = error.response.data.msg
            }
        },
        async getWorkspaces({ commit }) {
            try {
                const response = await service.get('workspaces')
                commit('SET_WORKSPACES', response.data.data)
                return response.data.data
            } catch (error) {
                console.log(error)
            }
        }
    }
}
