import { VCard } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('default-layout',[_c(VCard,{attrs:{"flat":""}},[_c(VTabs,{attrs:{"color":"primary"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,{attrs:{"value":0}},[_vm._v("Configuraciones generales")]),_c(VTab,{attrs:{"value":1}},[_vm._v("Configuración cloud")])],1)],1),_c(VWindow,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VWindowItem,{attrs:{"value":0}},[_c('general-setting',{staticClass:"ma-4",attrs:{"data":_vm.general},on:{"getSetting":_vm.getSettings}})],1),_c(VWindowItem,{attrs:{"value":1}},[_c('cloud-config',{staticClass:"ma-4",attrs:{"data":_vm.settings},on:{"getSetting":_vm.getSettings}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }