import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"width":"600"}},[_c(VCardTitle,{staticClass:"text-uppercase mb-4"},[_c('span',[_vm._v("Agregar colaboradores")]),_c(VSpacer),_c(VBtn,{attrs:{"small":"","icon":""},on:{"click":_vm.resetData}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('div',{staticClass:"d-flex align-baseline"},[_c('resource-input',{staticClass:"colaborator-add__input mr-2",attrs:{"label":"Correo electrónico","rules":"email","type":"email","clearable":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VBtn,{attrs:{"color":"primary","small":"","fab":""},on:{"click":_vm.pushColaborator}},[_c(VIcon,[_vm._v("mdi-account-plus")])],1)],1),(_vm.colaborators.length > 0)?_c('div',{staticClass:"mb-4"},[_c('h4',{staticClass:"mb-2 text-uppercase"},[_vm._v("Colaboradores:")]),_vm._l((_vm.colaborators),function(data,index){return _c('div',[_c(VChip,{staticClass:"mr-2 mb-2",attrs:{"label":""}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("mdi-account")]),_c('span',{staticClass:"d-block mr-4"},[_vm._v(_vm._s(data.email))]),_c(VBtn,{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.removeColaborator(index)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1)],1)})],2):_vm._e()]}}])}),_c(VBtn,{attrs:{"color":"primary","block":""},on:{"click":_vm.submitColaborator}},[_vm._v("Agregar colaboradores")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }