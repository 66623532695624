<template>
    <default-layout>
        <v-card flat>
            <v-tabs v-model="tab" color="primary">
                <v-tab :value="0">Configuraciones generales</v-tab>
                <v-tab :value="1">Configuración cloud</v-tab>
            </v-tabs>
        </v-card>
        <v-window v-model="tab">
            <v-window-item :value="0">
                <general-setting @getSetting="getSettings" :data="general" class="ma-4" />
            </v-window-item>
            <v-window-item :value="1">
                <cloud-config @getSetting="getSettings" :data="settings" class="ma-4" />
            </v-window-item>
        </v-window>
    </default-layout>
</template>

<script>
import DefaultLayout from '@/components/layout/Default.vue'
import ResourceInput from '@/components/resource/Input.vue'
import CloudConfig from '@/components/setting/CloudConfig.vue'
import GeneralSetting from '@/components/setting/GeneralSetting.vue'

export default {
    components: {
        DefaultLayout,
        ResourceInput,
        CloudConfig,
        GeneralSetting
    },
    data() {
        return {
            tab: null,
            settings: {},
            general: {}
        }
    },
    mounted() {
        this.getSettings()
    },
    methods: {
        async getSettings() {
            try {
                const { data } = await this.$axios.get('system/settings')
                const system = data.data.system
                const aws = data.data.aws

                this.general = {
                    plan: system.plan,
                    isDoubleVerification: system.isDoubleVerification
                }

                this.settings = {
                    access_id: aws?.access_id,
                    secret_key: aws?.secret_key,
                    bucket: aws?.bucket,
                    bucket_private_zone: aws?.zone,
                    bucket_files: aws?.bucketFiles,
                    bucket_files_zone: aws?.zoneBucketFiles,
                    bucket_environments: aws?.bucketEnviroments,
                    bucket_environments_zone: aws?.zoneBucketEnviroments,
                }
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>

<style lang="scss">
.setting-view {
    display: flex;
    gap: 10px;
    grid-template-columns: repeat(2fr);
}
</style>
