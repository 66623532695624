import service from '@/plugins/services'

export default {
    namespaced: true,
    state: {
        filter: '',
        isLocked: null,
        password: '',
        passwords: [],
        tags: []
    },
    mutations: {
        SET_PASSWORDS(state, value) {
            state.passwords = value
        },
        SET_TAGS(state, value) {
            state.tags = value
        },
        SET_FILTER(state, value) {
            state.filter = value
        },
        SET_TYPE_PASSWORD(state, value) {
            state.password = value
        },
        SET_UNLOCK(state, value) {
            state.isLocked = value
        }
    },
    actions: {
        async getPasswords({ state, commit }, payload) {
            try {
                const response = await service.get(`/secrets/workspaces/${payload.workspace}`, {
                    params: { tags: state.tags, filter: state.filter }
                })
                commit('SET_PASSWORDS', response.data.data)
            } catch (error) {
                console.error(error)
            }
        }
    }
}
